<template>
  <div class="check-wrapper">
    <div class="tab-wrapper">
      <van-tabs v-model="tabActive">
        <van-tab title="待执行"></van-tab>
        <van-tab title="我的点检"></van-tab>
      </van-tabs>
    </div>
    <div class="content-wrapper">
      <ExecutedList v-if="tabActive === 0" />
      <MyCheckList v-if="tabActive === 1" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs } from 'vant'
import ExecutedList from './components/ExecutedList'
import MyCheckList from './components/MyCheckList'
import 'vant/lib/tab/style'
import 'vant/lib/tabs/style'

Vue.use(Tab).use(Tabs)
export default {
  name: 'Check',
  components: {
    ExecutedList,
    MyCheckList,
  },
  data() {
    return {
      tabActive: 0,
    }
  },

  beforeRouteEnter(to, from, next) {
    if (from.path == '/') {
      next((vm) => {
        vm.tabActive = 0
      })
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.check-wrapper {
  @include wh(100%, 100%);
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding-top: 44px;
  position: relative;
  .tab-wrapper {
    @include wh(100%, 44px);
    position: fixed;
    left: 0;
    top: 0rem;
    /deep/.van-tab {
      .van-tab__text {
        font-size: 0.85rem;
        color: #333333;
      }
      &.van-tab--active {
        .van-tab__text {
          color: #4a8cff;
        }
      }
    }
    /deep/.van-tabs__line {
      background-color: #4a8cff;
    }
  }
  .content-wrapper {
  }
}
</style>
